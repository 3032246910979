import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { colors } from 'vuetify/lib';
import VueGtag from "vue-gtag";

// import vuetify from './plugins/vuetify'
import Viewer from 'vue-3d-viewer';
import { createPinia, PiniaVuePlugin } from 'pinia'

Vue.config.productionTip = false
Vue.use(Viewer)
Vue.use(Vuetify)
Vue.use(PiniaVuePlugin)
Vue.use(VueAxios, axios)
Vue.use(VueGtag, {
  config: { id: "G-LSC0N4MMQB" },
})
const pinia = createPinia()

new Vue({
  router,
  pinia,
  render: h => h(App),
  vuetify: new Vuetify(),
  // vuetify: new Vuetify({
  //   theme: {
  //     dark: true,
  //     themes: {
  //       dark: {
  //         primary: colors.cyan.base,
  //         secondary: colors.lightGreen.base,
  //         accent: colors.amber.base
  //       }
  //     }
  //   }
  // })
})
.$mount('#app')
