import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/model-upload',
    name: 'model-upload',
    component: () => import('../views/print-parameters.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/order-target.vue')
  },
  {
    path: '/success-order',
    name: 'success-order',
    component: () => import('../views/success-page.vue')
  },
  {
    path: '/',
    name: 'about-us',
    component: () => import('../views/about-us.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
